<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">PAN Data</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-12">
                        <div class="custom-form-group">
                            <label for="formpancardinput" class="form-label">Pan No.
                                <span class="text-danger">*</span></label>
                            <input type="text" v-model="pancardnumber" class="form-control text-uppercase"
                                id="formpancardinput" :disabled="inputdisabled" placeholder="Enter Pan No."
                                autocomplete="off" maxlength="10" />
                            <span class="custom-error" v-if="errpan">
                                {{ errpan }}
                            </span>
                            <span class="custom-error" v-if="errmsg">
                                {{ errmsg }}
                            </span>
                        </div>
                    </div>
                    <div class="
                            col-lg-4 col-md-4 col-12
                          " v-if="!sendotpbtnhide">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <button type="button" class="header-btn-outer btn btn-primary"
                                @click="getpancarddetails(pancardnumber)" :disabled="errStatus || pancardnumber.length == 0
                                    " :style="{ width: '104px' }">
                                <span v-if="!showloader">Get Details</span>
                                <div class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                " role="status" v-if="showloader">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12 text-end" v-if="clearbtnflag">
                        <div class="custom-form-group" style="margin-top: 26px;">
                            <button type="button" class="btn custom-view-detail-btn" @click="searchvoterclearbtn()">Clear
                                All
                            </button>
                        </div>
                    </div>
                </div>
                <div class="search-tab-outer-scroll" :style="{ height: '339px' }" v-if="mobiledetailshowflag">
                    <div class="voter-exit-box-outer mb-3">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Pan Number</label>
                                    <div class="from-label-value">
                                        {{ PanTabDetails.ad3 ? PanTabDetails.ad3 : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Name</label>
                                    <div class="from-label-value">
                                        {{ PanTabDetails.ad4 ? PanTabDetails.ad4 : "N/A" }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 col-12">
                                <div class="customer-label-group mb-2">
                                    <label class="form-label">Category</label>
                                    <div class="from-label-value">
                                        {{
                                            PanTabDetails.ad5 == 1
                                            ? "Person"
                                            : PanTabDetails.ad5 == 2
                                                ? "Business"
                                                : "N/A"
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="voter-exit-box-outer pb-3" v-if="nodatafoundflag">
                    <div>No records found.</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
export default {
    data() {
        return {
            v$: useValidate(),
            pancardnumber: '',
            errpan: '',
            errmsg: '',
            errStatus: false,
            showloader: false,
            PanTabDetails: "",
            mobiledetailshowflag: false,
            inputdisabled: false,
            sendotpbtnhide: false,
            nodatafoundflag: false,
            clearbtnflag: false,
        };
    },
    validations() {
        return {
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    watch: {
        pancardnumber(pancardnumber) {
            this.errmsg = "";
            this.validatePanCardNumber(pancardnumber);
            if (pancardnumber.length == 0) {
                this.errpan = "";
                this.errStatus = false;
            }
        },
    },
    mounted() {
    },
    methods: {
        getpancarddetails(e) {
            this.showloader = true;
            this.ApiService.getPANcardDetails({ pan_number: e }).then((data) => {
                if (data.status == true) {
                    this.PanTabDetails = data.data;
                    this.mobiledetailshowflag = true;
                    this.showloader = false;
                    this.inputdisabled = true;
                    this.sendotpbtnhide = true;
                    this.clearbtnflag = true;
                } else {
                    this.mobiledetailshowflag = false;
                    this.showloader = false;
                    this.nodatafoundflag = true;
                    this.clearbtnflag = true;
                }
            });
        },
        validatePanCardNumber(val) {
            this.errStatus = false;
            if (!this.validPAN(val)) {
                this.errpan = "Please enter valid pan number";
                this.errStatus = true;
            }
            if (this.errStatus == false) {
                this.errpan = "";
                return true;
            }
        },

        validPAN: function (e) {
            var re = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
            return re.test(e);
        },
        searchvoterclearbtn() {
            this.pancardnumber = '';
            this.errpan = '';
            this.errmsg = '';
            this.errStatus = false;
            this.showloader = false;
            this.PanTabDetails = '';
            this.mobiledetailshowflag = false;
            this.inputdisabled = false;
            this.sendotpbtnhide = false;
            this.nodatafoundflag = false;
            this.clearbtnflag = false;
        },
    },
};
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    margin-bottom: 15px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.search-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.search-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.voter-exit-box-outer {
    padding: 16px 16px 0;
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #bbd9cc;
}

.customer-label-group {
    margin-bottom: 20px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}
</style>